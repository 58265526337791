import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";

const middlewares = [thunk, createDebounce()];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadState) => (
    createStore(
        rootReducer,
        preloadState,
        composeEnhancers(applyMiddleware(...middlewares))
    )
);

export default configureStore;